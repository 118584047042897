import React from "react"
import css from './annoncesWysiwyg.scss'
const AdContentRender = ( props ) => {
    return(
        <div
            className={"wysiwyg"}
            dangerouslySetInnerHTML={{ __html: props.content }} 
        />
    )
}

export default AdContentRender