import React from "react"
import { Link as GatsbyLink } from "gatsby"
import AdContentRender from "./AdContentRender.js"
// import ReactMarkdown from "react-markdown"
import ResponseDelay from "./responseDelay"
import CopyLink from "./CopyLink"
import './annoncesWysiwyg.scss'
import SellerCard from './SellerCard.js'
import Loadable from 'react-loadable'
import logo from "../../static/svg/logo.svg"
import { FormattedMessage, FormattedDate } from 'react-intl';
import PriceCtn from '../price'
import AddToFavorite from '../AddToFavorite'
import DocumentsList from './DocumentList'
import {
    Heading,
    Box,
    Flex,
    Text,
    Grid,
    Spinner,
    Center
} from "@chakra-ui/react";
import { SiFacebook } from 'react-icons/si'


import { AdMeta } from "./Meta.js"
import { ImagePreview } from "./ImagePreview.js"
import { BiX } from "react-icons/bi"
import config from "../../../config"
import Gallery from './Gallery'
// ReactGA.initialize(process.env.GATSBY_GA_ID);
const siteConfig = require('../../../config')
var markdown = require("markdown").markdown;


export default class AdCardPortraitLarge extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ad: this.props.ad,
            isLoading: false,
            currentPictures: 0,
            loadGallery: false,
            isViewingPictures: this.props.isViewingPictures ? this.props.isViewingPictures : false,
            context: this.props.context
        };
    }
    componentDidMount() {
        const { ad, context } = this.state;

        typeof window !== "undefined" && window.gtag
            && window.gtag('event', 'page_view', {
                page_title: ad.title,
                page_location: `${config.domains[process.env.GATSBY_LANG]}/${ad.slug}`,
                page_path: ad.slug,
            })
    }
    getYtIdFromUrl(url) {
        var videoId = url.split('v=')[1]
        var ampersandPosition = videoId.indexOf('&')
        if (ampersandPosition != -1) {
            videoId = videoId.substring(0, ampersandPosition)
            return videoId
        } else {
            return videoId
        }
    }
    render() {

        const { ad, isLoading, isViewingPictures, loadGallery } = this.state;

        const { context } = this.state;
        // const Gallery = (loadGallery) ?
        //     Loadable({
        //         loader: () => import("./Gallery"),
        //         loading() {
        //             return <Center minH='375px' p={12}><Spinner size={'sm'} /></Center>
        //         }
        //     })
        //     : null

        if (ad.videoUrl) {
            var ytID = this.getYtIdFromUrl(ad.videoUrl)
        }
        return (
            <Box
                // zIndex={"sticky"}
                backgroundColor="gray.50"
                w={{ base: "full" }}
                mb={0}
                h='full'
            // overflow="hidden"
            >
                {isLoading ? (
                    <Spinner />
                ) : (
                    <>
                        {loadGallery && isViewingPictures ?
                            <Gallery
                                ad={ad}
                                isViewingPictures={isViewingPictures}
                                pictures={ad.pictures.url}
                                close={() => { this.setState({ isViewingPictures: false }) }}
                            />
                            :
                            ad.pictures
                                && ad.pictures.url
                                && ad.pictures.url.length > 0
                                ?
                                <ImagePreview
                                    ad={ad}
                                    isViewingPictures={isViewingPictures}
                                    handleOpenGallery={() => { this.setState({ isViewingPictures: isViewingPictures ? false : true, loadGallery: true }) }}
                                    backAction={this.props.backAction}
                                />
                                :
                                <Flex><Flex
                                    bgColor={'white'} borderRadius={'full'}>
                                    <Flex
                                        onClick={() => this.props.backAction()}
                                        // borderRadius={'full'}
                                        borderBottomRightRadius={'10px'}
                                        border='solid 1px'
                                        borderColor={'primary'}
                                        bgColor='primary    '
                                        color='primary'
                                        cursor={'pointer'}
                                        aria-label='Prev'

                                        fontWeight={'500'}
                                        px={3}
                                        py={2}

                                        alignItems={'center'}

                                        transition={`transform 200ms ease-in-out, background 200ms ease-in-out`}

                                        _hover={{
                                            color: 'white',
                                            bgColor: 'primary',
                                        }}
                                    >
                                        <Box mr={2}>
                                            <BiX />
                                        </Box>
                                        <FormattedMessage id="main.close" />
                                    </Flex>
                                </Flex> </Flex>

                        }
                        <Box>
                            <Grid
                                p={0}
                                templateColumns={{ base: "100%", lg: "45% 1fr" }}
                                m={"0 auto"}
                            // pb={10}
                            >
                                <Box
                                    bgColor={'white'}
                                    px={{ base: 4, lg: 0 }}
                                >
                                    <AdMeta
                                        ad={ad}
                                        context={this.props.context}
                                        openContact={this.props.contactOpen}
                                        ctaLabel={this.props.ctaLabel}
                                        isViewingPictures={isViewingPictures}
                                    />
                                </Box>

                                <Box

                                    p={{ base: 6, lg: 6 }}
                                >
                                    <Heading mb={5} as="h2" size="md" fontSize="16px" color={"gray.700"}>
                                        <FormattedMessage id="ad.title.details" />&nbsp;{' '}

                                        {ad.name ? ad.name : null}
                                        {ad.department ? ' - ' + ad.department : null}
                                    </Heading>
                                    {ad.childContentfulAdContentTextNode ?
                                        <Box className="wysiwyg">
                                            {/* <ReactMarkdown source={ad.childContentfulAdContentTextNode.content} /> */}
                                        </Box>

                                        : null}
                                    {ad.content ?
                                        ad.content.childMarkdownRemark ?
                                            <AdContentRender content={ad.content.childMarkdownRemark.html} />
                                            : <Box className="wysiwyg" h={this.props.limiteHeight ? '355px' : 'none'}>
                                                <Box
                                                    className="wysiwyg"
                                                    dangerouslySetInnerHTML={{
                                                        "__html": markdown.toHTML(ad.content)
                                                    }}
                                                />


                                                {/* <ReactMarkdown source={ad.content} /> */}
                                            </Box>
                                        : null}
                                    <DocumentsList data={ad.documentsList} />

                                </Box>
                            </Grid>
                            {ytID ?
                                <Box>
                                    <iframe width="100%" height="315" src={`https://www.youtube.com/embed/${ytID}`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </Box>
                                : null}
                            {!this.props.hideFooter ?
                                <>
                                    <Box
                                        borderTop='solid 1px'
                                        borderTopColor={'gray.100'}
                                        p={{ base: 6 }}
                                        pb={{ base: 32, lg: 6 }}
                                    >
                                        <Text
                                            fontSize="sm"
                                            color='gray.700'
                                            textAlign={'center'}
                                        >
                                            <FormattedMessage id="ad.obsolete.answer" />
                                        </Text>
                                    </Box>
                                </>
                                : null}
                        </Box>
                    </>
                )}
            </Box>
        )
    }
}
