import React from "react"
import AdCardPortraitLarge from "./PortraitLarge"
import SellerContactForm from "./SellerContactForm"
import { injectIntl } from "react-intl"
import { Box, Center, Spinner } from "@chakra-ui/react";

const contentful = require("contentful")
const client = contentful.createClient({
    space: process.env.GATSBY_CONTENTFUL_SPACE,
    environment: "master",
    accessToken: process.env.GATSBY_CONTENTFUL_TOKEN
})

class AdLoader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            ad: null,
            contactIsOpen: false
        }
    }
    componentDidMount() {
        let _this = this;
        client.getEntry(this.props.adID, { locale: process.env.GATSBY_LANG }).then(function (entry) {
            // console.log( entry )
            _this.setState({ ad: entry });
            _this.setState({ isLoading: false });
        })
    }
    contactHandleClose() {
        this.setState({
            contactIsOpen: false
        })
    }
    contactHandleOpen() {
        this.setState({
            contactIsOpen: true
        })
    }
    render() {
        const { ad, contactIsOpen } = this.state
        return (
            ad ? (

                <Box
                    maxW={'750px'}
                    mx='auto'
                    borderRadius={{ base: 0, md: "10px" }}
                    overflow={'hidden'}
                    id='ad-modal'
                    my={{ base: 0, lg: 10 }}
                    minH={{ base: '100vh', lg: 'auto' }}
                    bgColor='white'
                    boxShadow={'xl'}
                >
                    {contactIsOpen ?
                        <SellerContactForm
                            close={() => { this.contactHandleClose() }}
                            adBackendId={ad.fields.backendId}
                            sellerId={ad.fields.sellerId}
                        />
                        :


                        <AdCardPortraitLarge
                            key={ad.sys.id}
                            contentful_id={ad.sys.id}
                            ad={ad.fields}
                            id={ad.sys.id}
                            ctaLabel={this.props.ctaLabel ? this.props.ctaLabel : this.props.intl.formatMessage({ id: 'main.cta.ad.contact' })}
                            closeButtonLabel={this.props.intl.formatMessage({ id: "main.close" })}
                            context="main-website-modal"
                            backAction={this.props.handleUnload}
                            isViewingPictures={false}
                            contactOpen={() => { this.contactHandleOpen() }}
                        />}
                </Box>

            ) : <Center minH='100vh'><Spinner colorScheme={'white'} /></Center>
        )
    }
}

export default injectIntl(AdLoader)