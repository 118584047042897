import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { Box, Button, Icon } from '@chakra-ui/react'
import AuthService from '../../services/auth.service'
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
} from "@chakra-ui/react";

import { removeA } from '../utils/various'
import ShortUserCreationForm from './ShortUserCreationForm'
import { FormattedMessage } from 'react-intl';
import { BsFillSuitHeartFill } from 'react-icons/bs';
import { UserContext } from './userContext';
import Debug from '../utils/Debug';
const AddToFavorite = (props) => {
    const [userId, setUserId] = useState(AuthService.getCurrentUser() ? AuthService.getCurrentUser()['userId'] : null)

    const { adID } = props;

    const userContext = useContext(UserContext);
    const user = userContext ? userContext.user : null;

    const [showUserForm, setShowUserForm] = useState(false);
    const [currentFavoriteAds, setCurrentFavoriteAds] = useState([]);

    const isIterable = (value) => {
        return Symbol.iterator in Object(value);
    }

    const refreshFavorites = () => {
        // console.log(user.favorites.map(item => item.cmsDocumentId) !== currentFavoriteAds);
        if (
            user
            && user.favorites
            && isIterable(user.favorites)
            && user.favorites.map(item => item.cmsDocumentId) !== currentFavoriteAds
        ) {
            setCurrentFavoriteAds(user.favorites.map(item => item.cmsDocumentId));
        } else {
            // console.log('no favorites to update');
        }
    }


    useEffect(() => {
        console.log('use effect');

        refreshFavorites();

        const interval = setInterval(() => {
            refreshFavorites();
        }, 3000);
        return () => clearInterval(interval);
    }, []);



    const handleFavorite = async (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        if (userId) {
            saveFavorite(userId)
        } else {
            let userID = await createUser()
            // console.log( 'create account' )
        }
    }

    const createUser = () => {
        setShowUserForm(true)
    }

    const saveFavorite = (userId) => {

        const { adID } = props

        if (isFavorite) {
            removeA(currentFavoriteAds, adID)
            axios.delete(`${process.env.GATSBY_API_URL}/favorite/${adID}`, { data: { "cmsDocumentId": adID, "user": userId } })
                .then(function (result) {
                    console.log(result.data)
                })
        } else {
            if (currentFavoriteAds) {
                currentFavoriteAds.push(adID)
            } else {
                let favoriteAds = [adID]
            }

            axios.post(`${process.env.GATSBY_API_URL}/favorite`, { "cmsDocumentId": adID, "user": userId })
                .then(function (result) {
                    console.log(result.data)
                })

        }

    }

    const isFavorite = () => (
        currentFavoriteAds.includes(props.adID) ? true : false
    )

    return null;

    return (
        <Box
            display={!userId ? 'none' : 'block'}
        >
            <Popover
                placement="left"
                isOpen={showUserForm}
            >
                <PopoverTrigger>
                    <Button
                        onClick={(e) => { handleFavorite(e, props.adID) }}
                        borderRadius='30px'
                        title="Ajouter cette annonce à vos favoris"
                        colorScheme={isFavorite ? 'green' : 'gray'}
                        size={props.size}
                        p='.25rem 1rem'
                        h='1.75rem'
                    >
                        <Box
                            transform={isFavorite ? 'translateX( 0.5rem )' : 'translateX( -0.5rem )'}
                            transition='transform 200ms ease'


                        >
                            <BsFillSuitHeartFill
                                color={isFavorite ? 'white' : 'gray.600'}
                                transition='color 200ms ease'
                                size={props.size === 'sm' ? '10px' : '14px'}
                            />
                        </Box>

                    </Button>
                </PopoverTrigger>
                <PopoverContent
                    zIndex={4}
                    placement='right'
                >
                    <PopoverArrow />
                    <PopoverCloseButton onClick={(e) => { e.stopPropagation(); setShowUserForm(false); }} />
                    <PopoverHeader><FormattedMessage id="user.creation.short.form.label" /></PopoverHeader>
                    <PopoverBody>
                        <ShortUserCreationForm
                            visible={showUserForm}
                            finish={(createdUserId) => {
                                console.log(' user created', createdUserId)
                                setUserId(createdUserId)
                                setShowUserForm(!showUserForm);
                                saveFavorite(createdUserId)
                            }}
                        />
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </Box>
    )
}

export default AddToFavorite