import Axios from 'axios';
import * as React from 'react'
import { createContext, useState } from "react";
import config from '../../../config';
import AuthService from '../../services/auth.service';

export const UserContext = createContext();

// This context provider is passed to any component requiring the context
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const [userId, setUserId] = useState(
        AuthService.getCurrentUser() ? AuthService.getCurrentUser()['userId'] : null
    )


    React.useEffect(
        () => {
            if (userId) {
                try {
                    setInterval(async () => {
                        const res = await fetch(`${config.apiUrl}/user/full/${userId}/`);
                        const updatedUser = await res.json();
                        if (user !== updatedUser) {
                            setUser(updatedUser);
                        }
                        else {
                            console.log('else');
                        }
                    }, 5000);
                } catch (e) {
                    console.log(e);
                }
            }
        },
        [userId]
    )

    return (
        <UserContext.Provider
            value={{
                user,
                setUser
            }}
        >
            {children}
        </UserContext.Provider>
    );
};