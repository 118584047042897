import * as React from 'react'
import { createContext, useState } from "react";
import config from '../../../config';
import Debug from '../../utils/Debug';
import { AdModal } from './modal';

export const AdContext = createContext();

// This context provider is passed to any component requiring the context
export const AdProvider = ({ children }) => {
    const [ad, setAd] = useState('');

    // React.useEffect(
    //     () => {
    //         // if (userId) {
    //         //     try {
    //         //         setInterval(async () => {
    //         //             const res = await fetch(`${config.apiUrl}/user/full/${userId}/`);
    //         //             const updatedUser = await res.json();
    //         //             if (user !== updatedUser) {
    //         //                 setUser(updatedUser);
    //         //             }
    //         //             else {
    //         //                 console.log('else');
    //         //             }
    //         //         }, 5000);
    //         //     } catch (e) {
    //         //         console.log(e);
    //         //     }
    //         // }
    //     },
    //     []
    // )

    return (
        <AdContext.Provider
            value={{
                ad,
                setAd
            }}
        >
            {children}
        </AdContext.Provider>
    )

}