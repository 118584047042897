import React, { Component } from 'react';
import humanizeDuration from 'humanize-duration';
import {
    Text,
} from "@chakra-ui/react";
import { FormattedMessage } from 'react-intl';
import Axios from 'axios';

class ResponseDelay extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            averageDelayDuration: null,
            user: null
        };
    }
    componentDidMount() {
        const { userID } = this.props
        Axios.get(
            `${process.env.GATSBY_API_URL}/user/average-reply-delay/${userID}`
        )
            .then((response) => {
                this.setState({ averageDelayDuration: response.data.delay })
                this.setState({ user: response.data.name })
            })
    }
    render() {
        const { averageDelayDuration, user } = this.state
        const delay = (duration) => { return (humanizeDuration(duration * 1000, { units: ['h'], language: process.env.GATSBY_LANG, round: true })) }
        const Top = (props) => { return (<Text Text as="span">{delay(props.delay)}{' '}<span aria-label="top" role="img">👍</span></Text>) }
        const Perfect = (props) => { return (<Text Text as="span">{delay(props.delay)}{' '}<span aria-label="top" role="img">👌</span></Text>) }
        const Allstar = (props) => { return (<Text Text as="span">{delay(props.delay)}{' '}<span aria-label="top" role="img">⭐</span></Text>) }
        return (
            averageDelayDuration ?
                <Text mt="4px" fontSize="12px">{user} <FormattedMessage id="ad.reply.average.delay" />&nbsp;
                    <Text as="span" fontWeight="bold">
                        {
                            averageDelayDuration < 21600 ? <Allstar delay={averageDelayDuration} /> :
                                averageDelayDuration < 43200 ? <Perfect delay={averageDelayDuration} /> :
                                    averageDelayDuration < 86400 ? <Top delay={averageDelayDuration} />
                                        : <Text as="span">{delay(averageDelayDuration)}</Text>
                        }
                    </Text>
                </Text>
                : <Text mt="4px" fontSize="12px">
                    <FormattedMessage id="ad.sold.by" />&nbsp;
                    <Text as="span" fontWeight="bold">
                        {user}
                    </Text>
                </Text>
        )
    }
}

export default ResponseDelay