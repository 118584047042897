import React from 'react'
import {
    Box,
    Button,
    Flex,
    Heading,
    Stack,
    Text,
    Icon
} from '@chakra-ui/react'

const DocumentsList = ({ data }) => {
    const formatDocumentName = (name) => {
        if (!name) { return '' }
        let slashPosition = name.indexOf('/', 2)
        let documentNameOnly = name.substring((slashPosition + 1), name.length)
        return documentNameOnly
    }
    return (
        data ?
            data.url ?
                data.url[0] ?
                    <Stack spacing='.5rem' mt='2rem'>
                        <Text
                            fontWeight='bold'
                            fontSize='14px'
                            mb='1rem'
                            fontWeight='normal'
                            textTransform='uppercase'
                            letterSpacing='0.05rem'
                            color='blue.900'
                        >Documents&nbsp;:</Text>
                        {data.url.map(doc =>
                            formatDocumentName(doc) ?
                                <Flex
                                    as='a'
                                    bg='white'
                                    target='_blank'
                                    rel='noopener'
                                    href={`/documents${doc}`}
                                    key={`documentUpload-${doc}`}
                                    w="100%"
                                    p="8px"
                                    boxShadow='sm'
                                    borderRadius='sm'
                                    justify='space-between'
                                    alignItems='center'
                                >
                                    <Heading
                                        fontWeight='normal'
                                        as='span'
                                        maxW='80%'
                                        isTruncated
                                        fontSize='14px'>{formatDocumentName(doc)}</Heading>
                                    <Button
                                        onClick={() => { console.log(doc) }}
                                        bg='blue.brand'
                                        color='white'
                                        size="xs"
                                        _hover={{
                                            bg: 'blue.900',
                                            color: 'white'
                                        }}
                                    >
                                        <Icon name='attachment' />
                                    </Button>
                                </Flex>
                                : null
                        )}
                    </Stack>
                    : null
                : null
            : null
    )
}

export default DocumentsList