// 'use strict';
// const { Link } = require("gatsby");

module.exports = {
  contentFullSpaceId: 'xg7lbk8sfmzs',
  contentFullAccessToken: '315d9ab73fe1c8b4730aaa408d7298bb971cbcd0dd95de7ff56170edc17abbeb',
  languages: ['fr', 'en'],
  siteNames: {
    "fr": "Wanaboat.fr",
    "en": "iWannaboat.com"
  },
  domains: {
    "fr": "https://www.wanaboat.fr",
    "en": "https://iwannaboat.com"
  },
  emails: {
    "fr": "vhf@wanaboat.fr",
    "en": "vhf@iwannaboat.com"
  },
  apiUrl: process.env.GATSBY_API_URL,
  links: {
    "publish": {
      "fr": "/capitainerie/publier/",
      "en": "/harbour-office/publish/"
    },
    "account": {
      "fr": "/capitainerie/compte/",
      "en": "/harbour-office/account/"
    },
    "about": {
      "fr": "/capitainerie/",
      "en": "/harbour-office/"
    },
    "sellersHub": {
      "fr": "/capitainerie/vendeurs/",
      "en": "/harbour-office/sellers/"
    },
    "blog": {
      "fr": "/capitainerie/carnet-de-bord/",
      "en": "/harbour-office/blog/"
    },
    "donation": {
      "fr": "/capitainerie/don/",
      "en": "/harbour-office/donation/"
    },
    "donationThankyou": {
      "fr": "/capitainerie/don/merci/",
      "en": "/harbour-office/donation/thank-you/"
    }
  }
};